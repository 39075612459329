$(window).ready(function (){

	$("[data-scrollTo]").click(function(e) {
	    e.preventDefault();

	    var elementName = $(this).attr('data-scrollto');

	    var interrupt = true;
	    var interruptValue = $(this).attr('data-scrollto-interrupt');
	    if(interruptValue == 'false')
	    {
	    	interrupt = false;
	    }
	    
	    $.scrollTo($(elementName), 300, {
	    	axis: 'y',
	    	interrupt: interrupt,
	    	margin: true,
	    	offset: ($('#TopBar').height() * -2) + 23
	    });

	}); 

});