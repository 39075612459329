// $(document).ready(function(){

// 	var navBar = $('#scrollSpyMenu');

// 	if(!navBar) return;

// 	//var rightElement = $('#rightAffix');
// 	var header = $('#header');

// 	var previousWidth = 0;

// 	function addAffix(force)
// 	{
// 		if(force)
// 		{
// 			$(window).off('.affix')
// 			navBar.removeData('bs.affix').removeClass('affix affix-top affix-bottom')
// 		}

// 		navBar.affix({
// 			offset: {
// 				top: function () {
// 					return (this.top = header.outerHeight(true));
// 				}
// 			}
// 		});
// 	}

// 	addAffix(false);

// 	function defineConstraints(force) {

// 		var w = $('.website').outerWidth();

// 		if(!force && previousWidth == w)
// 		{
// 			return;
// 		}

// 		previousWidth = w;

// 		var margin = (w / 2);

//   		navBar.css('width', w)
//   				.css('left', '50%')
//   				.css('margin-left', '-' + margin + 'px');
// 	}

// 	navBar.on('affixed.bs.affix', function (e) {
// 		defineConstraints(true);
// 		$('#scrollTopButton').removeClass('hide');
// 	});

// 	navBar.on('affixed-top.bs.affix', function (e) {
//   		navBar.css('width', '').css('left', '').css('margin-left', '');
//   		$('#scrollTopButton').addClass('hide');
// 	});

// 	$(window).resize(function() {

// 		addAffix(true);

// 		if(navBar.hasClass('affix'))
// 		{
// 			defineConstraints(false);
// 		}
// 	});

// 	if(navBar.hasClass('affix'))
// 	{
// 		defineConstraints(true);
// 	}

// });