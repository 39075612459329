
if(0) {

	var GoogleMapsLoader = window.GoogleMapsLoader;

	GoogleMapsLoader.KEY = 'AIzaSyD2fqpKtocumU0E3Yj-BBhP1fFDg3umAtA';
	GoogleMapsLoader.VERSION = '3.14';

	GoogleMapsLoader.load(function (google) {

		$('[data-content="maps"]').each(function () {

			var element = $(this);

			var latitude = element.attr('data-maps-latitude');
			var longitude = element.attr('data-maps-longitude');
			var zoom = element.attr('data-maps-zoom');

			var hasMarker = (typeof element.attr('data-maps-marker') != 'undefined');

			var myLatlng = {lat: parseFloat(latitude), lng: parseFloat(longitude)};

			var mapOptions = {
				center: myLatlng,
				zoom: parseInt(zoom),
			};

			var map = new google.maps.Map(this, mapOptions);

			if (hasMarker) {
				var marker = new google.maps.Marker({
					position: myLatlng,
					map: map,
					title: element.attr('data-maps-marker')
				});
			}

		});
	});
}