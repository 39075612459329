//=require ../../node_modules/jquery/dist/jquery.js
//=require ../../node_modules/wow.js/dist/wow.js
//=require ../../node_modules/jquery.scrollto/jquery.scrollTo.js
//=require ../../node_modules/jquery.cookie/jquery.cookie.js
//=require ../../node_modules/typed.js/lib/typed.js

//=include ../../node_modules/bootstrap/js/dist/util.js
//=include ../../node_modules/bootstrap/js/dist/scrollspy.js
//=include ../../node_modules/bootstrap/js/dist/collapse.js
//=include ../../node_modules/bootstrap/js/dist/modal.js

//=require common/*.js