$(document).ready(function() {

	var navbarOffset = $('.navbar.navbar-default').height() - 2;

	$('.navbar .navbar-collapse a').click(function (e){

		e.preventDefault();

		//scroll smoothly in the page and take into account the offset

		$.scrollTo($($(this).attr('href')), 400, {
	    	axis: 'y',
	    	//interrupt: 'true',
	    	offset: -navbarOffset
	    });
	});

	var navbar = $('#bs-navbar-collapse');

	navbar.on('show.bs.collapse', function(){
		//console.info('navbar shown');
		//$('body').css('overflow', 'hidden');
	});

	navbar.on('hide.bs.collapse', function(){
		//console.info('navbar hidden');
		//$('body').css('overflow', 'auto');
	});

	$(window).on('scroll',function() {
		navbar.collapse('hide');
	});


});