
var Screenshow = function (element,conf) {
	this.init($(element),conf);
	return this;
};

// start screenshow when element is visible

Screenshow.prototype = {

	init : function (element,conf) {

		if (!element) {
			console.error('Cannot initialize on invalid element');
			return;
		}

		this.element = $(element);

		this.cnfattr = 'data-screenshow';

		if(conf) {

			this.conf = conf;

		} else {

			if(!this.setConfFromAttributes()) return;

		}

		// create images + onload loop event

		var imgs = [$('<img/>'), $('<img/>')];

		$(imgs).each(function (i, img) {

			img.on('load', function (ev) {this.loop(ev)}.bind(this));
			this.element.append(img);

		}.bind(this));

		this.images = imgs;

		// build slides list

		if(!this.conf.slides) {

			this.slides = [];

			var els = this.element.find('div[data-src]');

			$(els).each(function (i, el) {
				el = $(el);
				this.slides.push({'element': el, 'url': el.attr('data-src')});

			}.bind(this));

		} else {
			this.slides = this.conf.slides;
			delete(this.conf.slides);
		}

		this.visible = false;
		this.timer = null;
		this.index = 0;
		this.loops = 0;

		// has source

		if(this.conf.source) {
			console.log('This screenshow is managed by an other source.');
			return;
		}

		// copy

		if(this.conf.copy) {

			var cnf = jQuery.extend({'source':this,'slides':this.slides}, this.conf);

			delete(cnf.copy);

			this.copy = new Screenshow($(this.conf.copy),cnf);
		}

		// events

		this.onScrollResize();
		this.onScrollStart();

		this.addEvents();
	},

	setConfFromAttributes: function() {

		var a = this.element.attr(this.cnfattr);

		var conf;

		if (a) {
			eval('conf=' + a + ';');
		}

		if(!conf) {
			console.error('Cannot read configuration from attribute "'+this.cnfattr+'"',this.element);
			return false;
		}

		this.conf = {
			'duration': conf.duration || 5000,
			'transition_durartion': conf.transition_duration || 300,
			'copy': conf.copy ? $(conf.copy) : undefined
		};

		return true;
	},

	addEvents: function() {

		this.onScrollStartObserver = function() { this.onScrollStart();}.bind(this);
		this.onScrollResizeObserver = function() { this.onScrollResize();}.bind(this);

		$(window).on('scroll',this.onScrollStartObserver);
		$(window).on('resize',this.onScrollResizeObserver);

	},

	removeEvents : function() {

		$(window).off('scroll', this.onScrollStartObserver);
		$(window).off('resize', this.onScrollResizeObserver);

		this.onScrollStartObserver = function () {};
		this.onScrollResizeObserver = function() {};
	},

	onScrollStart: function (ev) {

		var w = $(window);

		var wt = w.scrollTop();
		var wh = w.height();

		var pos = wt + wh;

		var et = this.position.top;
		var eh = this.dimension.height;

		if (pos > this.position.top && wt < (et+eh)) {

			// visible

			if(!this.visible) {
				this.visible = true;
				this.run(true);
			}

		} else {

			// hidden

			if(this.visible) {
				this.visible = false;
			}
		}
	},

	// make sure element position is accurate

	onScrollResize: function (ev) {
		this.position = this.element.offset();
		this.dimension = {'height':this.element.height()};
	},

	run: function (start) {

		if(start) {
			console.info('Playing screenshow');
			this.visible=true;
		}

		if(!this.visible) return; // ignore

		var index = this.index;
		var slides = this.slides;

		if (!slides[index]) index = 0;

		var slide = this.slides[index];
		var img = this.images[0];

		img.attr('src', slide.url);

		this.index = index;
		this.current = img;

		if(this.copy) {

			var copy = this.copy;

			if(start) {
				copy.run(true);
			} else {
				clearTimeout(copy.timer);
				copy.timer = setTimeout(function () {copy.run();}, this.conf['duration'] / 4);
			}
		}
	},

	loop: function (ev) {

		if (this.timer) {
			clearTimeout(this.timer);
		}

		if (this.loops === 0) {
			// hide loader
			this.element.find('div.loading').hide();
		}

		this.images[0].fadeIn(this.conf['transition_duration']);
		this.images[1].fadeOut(this.conf['transition_duration']);

		// swap

		this.images = [this.images[1], this.images[0]];

		this.loops++;
		this.index++;

		// has source --> let the source control the run()

		if(this.conf.source) {
			return;
		}

		this.timer = setTimeout(function () {
			this.run();
		}.bind(this), this.conf['duration']);
	}
};

$(document).ready(function() {

	var els = $('div[data-screenshow]');
	els.each(function(i,el) {
		new Screenshow($(el));
	});

});