$(function(){

	var modal = $('#contact-modal');
	var body = $('#contact-body');
	var successElement = $('#contact-success');
	var sendButton = $('#contact-send');
	var footer = $('#contact-modal .modal-footer');

	// prevent scroll

	preventScrollEvents();

	// WHEN MODAL OPENS...

	modal.on('show.bs.modal', function(){

		lockSendButton(true);

		if(footer.hasClass('hide')) footer.removeClass('hide');

		var loaded_attr = 'data-html-loaded';

		if(modal.attr(loaded_attr) != 'true')
		{
			// get html url
			var url = body.attr('data-html-url');

			if(!url) {
				return alertFailedToLoad();
			}

			console.info('Loading Modal Body url='+url);

			// load HTML

			$.ajax({
				url : url,
				async:false,
				dataType: "html",
				method: "GET"

			}).done(function(data){

				console.log('modal loaded.');

				body.empty().html(data);

				// check

				var form = body.find('form');

				if(!form.length) return alertFailedToLoad();

				// prevent from reload

				modal.attr(loaded_attr,'true');

				sendButton.removeClass('hide');

				hideFormWarning();

			}).fail(function(){

				console.error('failed.');

				return alertFailedToLoad();
			});
		}
		else
		{
			console.info('already loaded.');
		}

		showError(false);
		lockSendButton(false);

		var form = body.find('form');
		$(form[0]).removeClass('hide');

	});

	// remove focus

	modal.on('hidden.bs.modal',function() {

		var links = $('[data-target="#contact-modal"]');

		if(links.length) links.blur();
	});

	// prevent Scroll when modal is open

	function preventScrollEvents() {

		return; // does not work (iOS)

		modal.on('shown.bs.modal',function() {
			$('body').css('overflow', 'hidden');
		});

		modal.on('hidden.bs.modal',function() {
			$('body').css('overflow', 'auto');
		});
	}

	function alertFailedToLoad() {

		// alert text
		var node = $('#contact-body-alert');

		alert(node.text());

		// hide modal
		modal.modal('hide');
	}

	function hideFormWarning() {

		var form = body.find('form');

		$(form[0]).on('focusin',function() {

			var el = $('#contact-use-app-warning');

			if(el) el.slideUp(300);
		});
	}

	sendButton.click(function(){

		// disable send button

		lockSendButton(true);

		// SEND form

		var data = {};

		var form =   $(body.find('form')[0]);
		var fields = form.find('input, textarea');
		
		for (var i = 0; i < fields.length; i++) {
			var field = $(fields[i]);
			var name = field.attr('name');
			data[name] = field.val();
		}

		console.info('DATA',data);

		if(!checkData(data)) {
			lockSendButton(false);
			return;
		}

		$.ajax({

			url : form.attr('action'),

			async:false,
			dataType: "json",
			method: form.attr('method'),
			data:data

		}).done(function(rsp){

			rsp = checkResponse(rsp);

			if(rsp.success) {

				saveChallenge();

				cleanForm();

				showSuccess();

				setTimeout(function () {
					lockSendButton(false);
					modal.modal('hide');
				}, 800);

			} else {
				lockSendButton(false);
				showError(rsp.error);
			}

		}).fail(function(out){
			console.error(out);

			lockSendButton(false);

			showError('fatal');
		});
	});

	function checkData(data) {

		for( var field in data) {

			var val = $.trim(data[field]);

			if(field=='email') {
				if(!data['email'].match(/.*\@.*/)) {
					val = '';
				}
			}

			if(!val) {
				showError(field);
				return false;
			}
		}

		return true;
	}

	function checkResponse(rsp) {

		if(jQuery.type(rsp)!=="object" || jQuery.type(rsp.success)!=="boolean") {
			console.error('Invalid response:',rsp);
			rsp = {'success':false};
		}

		if(!rsp.success && !rsp.error) {
			rsp.error='unknown';
		}

		console.log('Reponse:',rsp);
		
		return rsp;
	}

	function lockSendButton(lock) {

		if(lock) {
			sendButton.attr('disabled',true);
			sendButton.addClass('disabled');
		} else {
			sendButton.attr('disabled',false);
			sendButton.removeClass('disabled');
		}
	}

	function saveChallenge() {

		// do this before emptying the form !

		var form = $(body.find('form')[0]);

		var div = $('#contact-challenge-question');

		if(div) {
			var inp = form.find('input[name=challenge_response]');
			inp.attr('type','hidden');
			form.append(inp);
			div.remove();
		}
	}

	function showError(error) {

		// hide all errors

		var els = body.find('div.alert');

		els.removeClass('hide');
		els.hide();

		if(error===false) return true;

		// show error

		var el = body.find('div[data-error='+error+']');

		if(!el.length) el = body.find('div[data-error=unknown]');

		$(el[0]).fadeIn(300);

		// focus input

		var inp = body.find('input[name='+error+'] , textarea[name='+error+']');

		if(inp[0]) {
			$(inp[0]).focus();
		}

		return true;
	}

	function showSuccess() {

		showError(false);

		var form = body.find('form');
		$(form[0]).addClass('hide');

		var el = $('#contact-success');

		el.fadeIn(300);
	}

	function cleanForm(){

		//empty all elements
		body.find('input[type!="hidden"], textarea').each(function(){
			$(this).val('');
		});

		//hide success & show form
		if(!successElement.hasClass('hide')) successElement.addClass('hide');
		if(body.hasClass('hide')) body.removeClass('hide');
		if(sendButton.hasClass('hide')) sendButton.removeClass('hide');
	}

});

// handle /site/#!show=contact

$(document).ready(function() {

	var hash = window.location.hash.substr(1);

	if(hash.indexOf("!show=contact")===0) {
		$('#contact-modal').modal('show');
		window.location.hash='';
	}

});