// console

if ( !window['console']) { var n = function(){}; window['console'] = {debug:n, log:n, info:n, warn:n, error:n, assert:n, dir:n, dirxml:n, trace:n, group:n, groupEnd:n, time:n, timeEnd:n, profile:n, profileEnd:n, count:n }; }

/*
 * PROFILER
 */

function show_profiler(show) {
	if(show) {
		$('div.profiler')[1].style.display='none';
		$('div.profiler')[0].style.display='block';
		$.cookie('profiler',0);
	} else {
		$('div.profiler')[0].style.display='none';
		$('div.profiler')[1].style.display='block';
		$.cookie('profiler',1);
	}
	return false;
}

var Z = {};

Z.scrollSpyNavBar = function () {

	var topbar = $('#TopBar');
	var menu = topbar.find('.spy-menu');
	var header = $('#Header');

	if (menu.length == 0) {
		return;
	}

	function switchMenu(show) {

		if (!show) {
    		topbar.removeClass('show-spy');
    	} else {
    		topbar.addClass('show-spy');
    	}
	}

	if (header.length > 0) {

		function checkHeader() {
			var offset = $(document).scrollTop();
			switchMenu(offset >= header.height());
		}

	    $(window).scroll(function () {
			checkHeader();
		});

		checkHeader();

	} else {

		$(window).on('activate.bs.scrollspy', function () {
			switchMenu(menu.find('a.active').length > 0);
		});
	}
};

Z.collapseManager = function () {

	var container = $('.Section.infos');
	if (container.length == 0) {
		return;
	}

	var items = container.find('.collapse');
	if (items.length == 0) {
		return;
	}

	var showingAll = true;

	function updateStatsIfNeeded() {

		var breakpointWidth = 767;
		var width = $(window).width();

		if (width < breakpointWidth && showingAll) {
			showingAll = false;
			items.collapse('hide');
		} else if (width >= breakpointWidth && !showingAll) {
			showingAll = true;
			items.collapse('show');
		} 
	}

	items.on('hide.bs.collapse', function () {
		return !showingAll;
	});

	$(window).resize(function() {
		updateStatsIfNeeded();
	});

	updateStatsIfNeeded();
};

// ready !

$(document).ready(function () {

    //init scroll spy for the navbar
    Z.scrollSpyNavBar();

    //show or hide collapsed content depending on the size of the screen
    Z.collapseManager();
});