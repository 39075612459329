
// start WOW animations

new WOW().init();

//fix a bug on google chrome when the animation is done and the opacity is set to the initial state (invisible...)
$(document).ready(function(){
	//$('.Header .wow').hide();
});

// add pulse on DownloadButtons

$(document).ready(function() {

	var buttons = $('a.DownloadButton');

	buttons.each(function(i,el){

		$(el).attr('target','_new');

		$(el).hover(
			function() {
				// remove "wow Effect" class
				var s = $(this).attr('class');
				var i = s.indexOf('wow');
				if(i>0) {
					s = s.substr(i);
					$(this).removeClass(s);
				}
				// reset style
				$(this).attr('style','');

				// add effect
				$(this).css('animation-duration','0.5s');
				$(this).addClass('animated pulse');
			},
			function() { $(this).removeClass('animated pulse'); }
		);
	});

});