
var AnimatedTitle = function(element) {
	this.init(element);
	return this;
};

AnimatedTitle.prototype = {

	init : function(element) {

		var span = $(element).find('span')[0];

		if(!span) return;

		span = $(span);
		var spanClass = "animated-title-" + Math.random().toString(36).substring(5);
		span.addClass(spanClass);

		var texts = [];

		span.find('em').each(function () {
			texts.push($(this).text());
		});

		if(texts.length == 0) return;

		span.empty();

		var typed = new Typed('.' + spanClass, {
			strings: texts,
			typeSpeed: 80,
			backSpeed: 40,
			backDelay: 1200,
			loop: true,
			showCursor: false
		});

		// this.container = $(span);

		// var text = $(this.texts[0]).clone();
		// text.css('display', 'none');

		// this.container.append(text);

		// this.texts.push(text); // loop trick

		// // $(this.texts).css('display','inherit');

		// this.onResizeObserver = function() { this.onResize()}.bind(this);

		// $(window).on('resize',this.onResizeObserver);

		// this.onResize();

		// console.info('Starting Animated Title');

		// this.animate(0,true);
	}

	// animate : function(i,timer) {

	// 	if(!timer) return setTimeout(function() { this.animate(i,true);}.bind(this),2000);

	// 	var text = this.texts[i];

	// 	if(!text) {
	// 		// loop
	// 		this.container.scrollTop(0);
	// 		i=1;
	// 		text = this.texts[i];
	// 	}

	// 	this.container.scrollTo(text,300, {
	// 		easing: 'swing',
	// 		onAfter: function () {this.animate(i + 1);}.bind(this)
	// 	});
	// },

	// onResize : function() {

	// 	// span height = span.em[0] height

	// 	var span = this.container;

	// 	var text = this.container.find('em')[0];
	// 	var h = $(text).height();

	// 	this.container.css('height',h+"px");
	// }
};

$(document).ready(function(){

	var title = $('#Header h1.Animated');

	if(!title) return;

	new AnimatedTitle(title);
});

